import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MyContextProvider } from "./context/credentialContext";
import { Toaster } from "react-hot-toast";

import Home from "./pages/Home";
import ProductPage from "./pages/ProductPage";
// import Cart from "./pages/Cart";
import TicketPage from "./pages/TicketPage";
import Merch from "./pages/Merch";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Checkout from "./pages/CheckOut";
import VendorPayment from "./pages/Vendor";
import { useEffect } from "react";

function VendorRedirect() {
  useEffect(() => {
    window.location.href =
      "https://docs.google.com/forms/d/e/1FAIpQLSdGrvBZi-KlnlG0bZlfZwHLD3e4-pCHAxZSpiKqMkB1q6AeVw/viewform?usp=sf_link";
  }, []);
}

function App() {
  return (
    <MyContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<ProductPage />} />
          {/* <Route path="/cart" element={<Cart />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/tickets" element={<TicketPage />} />
          <Route path="/merch" element={<Merch />} />
          <Route path="/merch/:slug" element={<ProductPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/vendor" element={<VendorRedirect />} />
          <Route path="/vendorpayment" element={<VendorPayment />} />
        </Routes>
      </Router>
      <Toaster />
    </MyContextProvider>
  );
}

export default App;
