import React, { useContext, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import { BASE_URL } from "../config/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { MyContext } from "../context/credentialContext";
import toast from "react-hot-toast";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) {
    navigate("/");
  }

  const { total } = location.state;

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    phone: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "Nigeria",
  });

  const [errors, setErrors] = useState({});
  const emailVal = Cookies.get("email");

  const config = {
    reference: new Date().getTime().toString(),
    email: Cookies.get("email") ? Cookies.get("email") : emailVal,
    amount: total * 100,
    publicKey: "pk_live_67097045899cb4b2a90dc5b058df0eb88eec5098",
  };

  const initializePayment = usePaystackPayment(config);
  const { contextValue, setContextValue } = useContext(MyContext);

  const onSuccess = (reference) => {
    handleCheckout();
  };

  const onClose = () => {
    console.log("closed");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.phone) newErrors.phone = "phone number is required";
    if (!formData.streetAddress)
      newErrors.streetAddress = "Street Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      initializePayment(onSuccess, onClose);
    } else {
      toast.error("Please fill in all the required details");
    }
  };

  const handleCheckout = () => {
    let userId = Cookies.get("userId");
    const url = `${BASE_URL}/orders/${userId}`;

    axios
      .post(url, { payment_status: "paid", email: emailVal, ...formData })
      .then((response) => {
        const result = response.data;
        const { status, message } = result;

        if (status == "SUCCESS") {
          toast.success(message);
          setContextValue(contextValue - 1);
          navigate("/");
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(
          "An error occured, please check your connection and try again."
        );
      });
  };

  return (
    <div className="flex flex-col md:flex-row justify-center space-x-5 md:items-start items-center py-10 px-4 sm:px-6 lg:px-8 font-Neuehaasdisplay">
      <form onSubmit={handleSubmit} className="w-full max-w-lg bg-black">
        <div className=" pb-5 bg-white px-5">
          <h2 className="text-3xl font-medium text-black mb-3 pt-3">
            Customer Info
          </h2>
          <hr />
          <label className="block text-sm font-medium text-black mt-5">
            Email
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`mt-1 block w-full  border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
          </label>
        </div>

        <div className="border-b border-gray-200 py-4 bg-white mt-10 px-5">
          <h2 className="text-3xl font-medium text-black mb-5">
            Shipping Address
          </h2>
          <hr />
          <label className="block text-sm font-medium text-black mt-3">
            Full Name
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className={`mt-1 block w-full  border ${
              errors.fullName ? "border-red-500" : "border-gray-300"
            } px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.fullName && (
            <p className="text-red-500 text-sm">{errors.fullName}</p>
          )}
          <label className="block text-sm font-medium text-black mt-3">
            Phone Number
          </label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`mt-1 block w-full  border ${
              errors.phone ? "border-red-500" : "border-gray-300"
            } px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.phone && (
            <p className="text-red-500 text-sm">{errors.phone}</p>
          )}

          <label className="block text-sm font-medium text-black mt-3">
            Street Address
          </label>
          <input
            type="text"
            name="streetAddress"
            value={formData.streetAddress}
            onChange={handleChange}
            className={`mt-1 block w-full  border ${
              errors.streetAddress ? "border-red-500" : "border-gray-300"
            } px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.streetAddress && (
            <p className="text-red-500 text-sm">{errors.streetAddress}</p>
          )}

          <label className="block text-sm font-medium text-black mt-3">
            City
          </label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className={`mt-1 block w-full  border ${
              errors.city ? "border-red-500" : "border-gray-300"
            } px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}

          <label className="block text-sm font-medium text-black mt-3">
            State
          </label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className={`mt-1 block w-full  border ${
              errors.state ? "border-red-500" : "border-gray-300"
            } px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.state && (
            <p className="text-red-500 text-sm">{errors.state}</p>
          )}

          <label className="block text-sm font-medium text-black mt-3">
            Country
          </label>
          <input
            type="text"
            name="country"
            disabled
            value={formData.country}
            onChange={handleChange}
            className={`mt-1 block w-full  border ${
              errors.country ? "border-red-500" : "border-gray-300"
            } px-3 py-2 text-black focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.country && (
            <p className="text-red-500 text-sm">{errors.country}</p>
          )}
          {/* Repeat similar blocks for streetAddress, city, state, zip, country */}
        </div>

        <div className="border-t border-gray-200 py-4">
          <button
            type="submit"
            className="w-full bg-cream text-black py-2  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Place Order
          </button>
        </div>
      </form>

      <div className="">
        <div className=" w-[300px] px-3 bg-white py-5">
          <h3 className="text-3xl font-medium text-black mb-3">
            Order Summary
          </h3>
          <hr />
          <div className="flex flex-col mt-5">
            <p className="mt-1 text-sm font-light  text-gray-500">
              Subtotal: <span>NGN {total ? total.toLocaleString() : 0}</span>
            </p>
            <p className="mt-1 text-sm font-light text-black">
              <span className="font-bold">
                Total: NGN {total ? total.toLocaleString() : 0}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
