import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import arrow from "../imgs/arrow.svg"; // your arrow image path
import menuIcon from "../imgs/menu.svg"; // your hamburger icon image path
import closeIcon from "../imgs/close.svg"; // your close icon image path
import logo from "../svg/logo.svg"; // your close icon image path

export default function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const location = useLocation();

  // Conditionally apply bg-orange-500 when the current route is /tickets
  const ticketLinkClass =
    location.pathname === "/tickets"
      ? "border border-border-subtle px-1 items-center py-1 bg-orange-500 hover:bg-orange-600"
      : "border border-border-subtle px-1 items-center py-1 hover:bg-orange-500";

  return (
    <div>
      <nav className="flex items-center justify-between mx-5 py-3 relative">
        {/* Title (on small screens it's left aligned) */}
        <div className="text-left">
          <Link to="/" className="font-space_age font-bold text-xl md:text-2xl">
            <img
              src={logo}
              alt="stunner rave logo "
              className="w-[50px] block md:hidden"
            />
            <span className="md:inline-block hidden">Stunner Rave</span>
          </Link>
        </div>
        {/* Regular Menu (Visible on larger screens) */}
        <ul className="hidden md:flex space-x-7 font-Neuehaasdisplay font-light text-sm items-center">
          <li className="hover:font-bold ">
            <Link to="/merch" className="leading-normal tracking-widest">
              MERCH
            </Link>
          </li>
          <li className="hover:font-bold ">
            <Link to="/about" className="leading-normal tracking-widest">
              ABOUT US
            </Link>
          </li>

          <li className="hover:font-bold ">
            <Link to="/contact" className="leading-normal tracking-widest">
              CONTACT US
            </Link>
          </li>
          <li className="hover:font-bold ">
            <Link to="/tickets" className="leading-normal tracking-widest">
              TICKETS
            </Link>
          </li>
          {/* Early Bird Tickets Link */}
          <li className={ticketLinkClass}>
            <img src={arrow} alt="arrowsvg" className="w-5 inline-block mr-2" />
            <Link to="/vendor" className="py-5 leading-normal tracking-widest">
              BECOME A VENDOR
            </Link>
          </li>
        </ul>
        {/* Hamburger Menu (Visible on small screens) */}
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            <img
              src={isSidebarOpen ? closeIcon : menuIcon}
              alt="Menu Icon"
              className="w-6"
            />
          </button>
        </div>
        {/* Sidebar Menu (Visible on small screens) */}

        <div
          className={`fixed top-0 right-0 h-full w-64 bg-black text-white transform ${
            isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out z-50 md:hidden`}
        >
          {/* Sidebar Content */}
          <div className="mt-10">
            <ul className="space-y-4 px-5 font-Neuehaasdisplay font-light">
              <li className="border-b border-border-subtle py-2 text-sm">
                <Link to="/merch" onClick={toggleMenu}>
                  MERCH
                </Link>
              </li>
              <li className="border-b border-border-subtle py-2 text-sm">
                <Link to="/about" onClick={toggleMenu}>
                  ABOUT US
                </Link>
              </li>
              <li className="border-b border-border-subtle py-2 text-sm">
                <Link to="/merch" onClick={toggleMenu}>
                  MERCH
                </Link>
              </li>
              <li className="border-b border-border-subtle py-2 text-sm">
                <Link to="/contact" onClick={toggleMenu}>
                  CONTACT US
                </Link>
              </li>
              <li className="hover:font-bold ">
                <Link to="/tickets" className="leading-normal tracking-widest">
                  TICKETS
                </Link>
              </li>
              <li className="py-2 border border-border-subtle px-1 items-center mx-auto">
                <img
                  src={arrow}
                  alt="arrowsvg"
                  className="w-5 inline-block mr-2"
                />
                <Link to="/vendor" className="text-sm" onClick={toggleMenu}>
                  BECOME A VENDOR
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {isSidebarOpen && (
          <div
            onClick={toggleMenu}
            className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
          ></div>
        )}
      </nav>
    </div>
  );
}
