import React, { useEffect } from "react";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import Vendor from "../components/Vendor.jsx";

export default function VendorsPage() {
  useEffect(() => {
    document.title = "Vendors Payment";
  }, []);
  return (
    <div className="h-full w-full">
      <Header />
      <Vendor />
      <Footer />
    </div>
  );
}
